<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="container">
    <div class="row" v-if="properties">
      <div class="col-md-12 inquiry-wrapper" v-bind:class="{'open-filter-box': filterBoxActive}">
        <div class="add-property-title d-md-flex align-items-baseline">
          <div> {{ $t('agency-my-offers-title') }}</div>
          <div class="d-flex ms-md-auto properties-sort-wrapper">
            <div class=" properties-sort-nav"
                 @click="activeInactiveSwitch = 'active'"
                 :class="{ 'active-button': activeInactiveSwitch === 'active' }">
              {{ $t('active') }}
            </div>
            <div class="properties-sort-nav"
                 @click="activeInactiveSwitch = 'inactive'"
                 :class="{ 'active-button': activeInactiveSwitch === 'inactive' }"
            > {{ $t('inactive') }}
            </div>
            <div class="d-flex ms-auto sort-filter d-md-none">
              <div class="sort-by me-1">{{ $t('sort-by') }}</div>
              <div class="d-flex align-items-center sort-by-button me-3">
                <div class="me-1" @click="filterBoxActive = !filterBoxActive"> {{ selectedOption.title }}</div>
                <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round"/>
                  <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="sort-filter-modal ">
                <div class="sort-item" v-for="option in sortingOptions" @click="selectOption(option)"> {{
                    option.title
                  }}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="d-flex align-items-center position-relative"
             v-bind:class="{'open-filter-box': filterBoxActive}"
        >
          <div class="d-flex col-md-9">
            <div class="checkbox d-flex align-items-start me-2">
              <CheckBox v-model="showRequested"/>
              <div class="checkbox-label"> {{ $t('with-viewing-requests') }}</div>
            </div>
            <div class="checkbox d-flex align-items-start me-2">
              <CheckBox v-model="showDeletedOnly"/>
              <div class="checkbox-label"> {{ $t('deleted-properties') }}</div>
            </div>
            <div class="checkbox d-flex align-items-start">
              <CheckBox v-model="showPremiumOnly"/>
              <div class="checkbox-label"> {{ $t('premium-only-properties') }}</div>
            </div>
          </div>
          <div class=" ms-auto  sort-filter d-none d-md-flex">
            <div class="sort-by me-1">{{ $t('sort-by') }}</div>
            <div class="d-flex align-items-center sort-by-button me-3">
              <div class="me-1" @click="filterBoxActive = !filterBoxActive"> {{ selectedOption.title }}</div>
              <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round"/>
                <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="sort-filter-modal ">
              <div class="sort-item" v-for="option in sortingOptions" @click="selectOption(option)"> {{
                  option.title
                }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="properties.length > 0">
          <div class="inquiry-item" v-for="property in properties">
            <AdminPropertyListItem
                :id="property.id"
                :date="property.date"
                :time="property.time"
                :propertyid="property.propertyid"
                :title="property.title"
                :adnumber="property.number"
                :image="property.images[0].src"
                :hidePreviewButton="showDeletedOnly"
                :hideDeleteButton="showDeletedOnly"
                @itemDeleted="handleItemDeleted"
            />
          </div>
          <div class="pagination">
            <Pagination
                :size="'small'"
                :pages="Math.ceil(totalProperties/limit)"
                :route="'AdminPropertyList'"
                @pageChanged="handlePageChange"
            />
          </div>
        </div>
        <div v-else>
          <div class="alert alert-warning mt-3" role="alert">
            {{ $t('no-properties-found') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
