export default {
    name: 'AdminPropertyListItem',
    props: {
        id: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: ''
        },
        views: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        adnumber: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: null
        },

    },
    methods: {
        async deleteProperty(id) {
            this.$emit('itemDeleted', id)
        }
    }
}